/* eslint-disable no-restricted-globals */
import React, { useState } from "react";
import { FiEdit } from "react-icons/fi";
import { MdArrowBackIos } from "react-icons/md";
import RateTab from "./Tabs/RateTab";
import CommissionTab from "./Tabs/CommissionTab";
import ToolTip from "../../../../../components/ToolTip";
import {
  GET_SINGLE_SCHOOL_MDR_INFO,
  GET_TRANSACTION_REPORT,
} from "../../../../../Qurries";
import { useQuery } from "@apollo/client";
import ModeAction from "./Tabs/ModeAction";
import ConfirmationBox from "../../../../../components/ConfermationBox";
import Modal from "../../../../../components/Modal/Modal";
export const validModes = [
  "wallet",
  "cardless",
  "netbanking",
  "pay_later",
  "upi",
  "card",
];

function SchoolRate({
  data: schoolId,
  setOpen,
  requestList,
  setfinalPaymentInfo,
  finalPaymentInfo,
  isBulk,
  hasChanged,
  setHasChanged,
}: any) {
  const [activeTab, setActiveTab] = useState(1);
  const [paymentRange, setPaymentRange] = useState([]);
  const [openModal, setModal] = useState(false);
  const { data: transactionReport } = useQuery(GET_TRANSACTION_REPORT);
  const { data, loading } = useQuery(GET_SINGLE_SCHOOL_MDR_INFO, {
    variables: {
      school_id: schoolId,
    },
  });

  const schoolTransactions = transactionReport?.getTransactionReport
    .filter((d: any) => d.school_id === schoolId && d.status.toUpperCase() === "SUCCESS")
    .map((s: any) => s);
  const status = ["INITIATED"];
  const PENDING_REQUEST = requestList?.filter((request: any) => {
    if (
      request?.school_id.includes(schoolId) &&
      status.includes(request.status)
    ) {
      return request;
    }
  });

  if (loading) return <div>Loading..</div>;
  return (
    <div>
      <Modal open={openModal} setOpen={setModal}>
        <ConfirmationBox
          setOpen={setModal}
          funtion={async () => {
            setOpen(false);
          }}
          confirmationText="Changes you make will not be saved."
          closeText="Changes you make will not be saved."
          ButtonText="Close"
        />
      </Modal>
      <div className="p-4 flex bg-white justify-between items-center overflow-hidden">
        <p
          onClick={() => {
            if (hasChanged === true) {
              setModal(true);
            } else {
              setOpen(false);
            }
          }}
          className="flex text-xs cursor-pointer text-[#505E8E] items-center"
        >
          <MdArrowBackIos className="mr-2" />
          Back
        </p>
        <p className=" text-[#1B163B] text-sm font-medium">
          Status:{" "}
          <span className=" text-[#6687FF] ">
            {data?.getSchoolMdrInfo.merchantStatus}
          </span>
        </p>
      </div>
      <div className="px-8 space-y-2 py-4 bg-white">
        <ToolTip
          text={data?.getSchoolMdrInfo.school_name}
          width="max-w-[28rem]"
        >
          <h1 className=" text-xl w-full capitalize  truncate font-medium text-edviron_black">
            School Name: {data?.getSchoolMdrInfo.school_name}
          </h1>
        </ToolTip>

        <p className="  text-sm font-medium text-edviron_black">
          School ID: {data?.getSchoolMdrInfo.school_id}
        </p>
        <p className="  text-sm font-normal text-edviron_black">
          Last Updated:{" "}
          {new Date(
            data?.getSchoolMdrInfo?.requestUpdatedAt,
          ).toLocaleDateString("hi")}
        </p>
      </div>
      <div className=" bg-white space-x-6">
        <button
          onClick={() => setActiveTab(1)}
          className={
            activeTab === 1
              ? "text-[#6687FF] border-b-2 border-[#6687FF] px-4 py-3 font-semibold text-[12px] "
              : "  py-3 px-4 font-semibold bg-transparent text-[12px] text-left  text-[#777777]"
          }
        >
          Rates/Charges
        </button>
        <button
          onClick={() => setActiveTab(2)}
          className={
            activeTab === 2
              ? "text-[#6687FF] border-b-2 border-[#6687FF] px-4 py-3 font-semibold text-[12px]  "
              : "  py-3 px-4 font-semibold bg-transparent text-[12px] text-left  text-[#777777]"
          }
        >
          Commissions
        </button>
        <button
          onClick={() => setActiveTab(3)}
          className={
            activeTab === 3
              ? "text-[#6687FF] border-b-2 border-[#6687FF] px-4 py-3 font-semibold text-[12px]  "
              : "  py-3 px-4 font-semibold bg-transparent text-[12px] text-left  text-[#777777]"
          }
        >
          Action
        </button>
      </div>
      {activeTab === 1 && (
        <RateTab
          data={data?.getSchoolMdrInfo?.platform_charges}
          schoolId={[data?.getSchoolMdrInfo?.school_id]}
          PENDING_REQUEST={PENDING_REQUEST}
          setOpen={setOpen}
          setPaymentRange={setPaymentRange}
          paymentRange={paymentRange}
          setfinalPaymentInfo={setfinalPaymentInfo}
          finalPaymentInfo={finalPaymentInfo}
          isBulk={isBulk}
          hasChanged={hasChanged}
          setHasChanged={setHasChanged}
        />
      )}
      {activeTab === 2 && <CommissionTab data={schoolTransactions} />}
      {activeTab === 3 && (
        <div className="p-8 space-y-4">
          <div className="grid grid-cols-2">
            <div>
              <p className=" text-[#777777] text-xs font-normal">
                Payment Mode
              </p>
            </div>
            <div>
              <p className=" text-[#777777] text-xs font-normal">Status</p>
            </div>
          </div>
          {validModes.map((mode: string) => {
            return (
              <ModeAction
                key={mode}
                disableMode={data?.getSchoolMdrInfo.disabled_modes}
                mode={mode}
                schoolID={schoolId}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

export default SchoolRate;
