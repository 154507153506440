import React from "react";
import { MdArrowBackIos } from "react-icons/md";
import KycDetails from "./KycDetails";
import SelectCommission from "./SelectCommission";

function InvoiceTab({ setOpen }: any) {
  return (
    <div className="py-4 bg-[#EDF1F4]">
      <p
        onClick={() => {
          setOpen(false);
        }}
        className="flex p-4 text-xs cursor-pointer text-[#505E8E] items-center"
      >
        <MdArrowBackIos className="mr-2" />
        Back
      </p>
      <KycDetails />
      <SelectCommission setOpen={setOpen} />
    </div>
  );
}

export default InvoiceTab;
