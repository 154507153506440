import { gql } from "@apollo/client";

export const LOG_IN_TRUSTEE = gql`
  mutation LoginTrustee($email: String!, $password: String!) {
    loginTrustee(email: $email, password: $password) {
      token
    }
  }
`;

export const GET_USER = gql`
  query GetUserQuery {
    getUserQuery {
      _id
      name
      email_id
      apiKey
      role
      phone_number
      trustee_id
      brand_name
      gstIn
      residence_state
      bank_details {
        account_holder_name
        account_number
        ifsc_code
      }
      base_mdr {
        trustee_id
        platform_charges {
          platform_type
          payment_mode
          range_charge {
            upto
            charge_type
            charge
          }
        }
      }
    }
  }
`;

export const GENERATE_API_KEY = gql`
  mutation CreateApiKey($otp: String!) {
    createApiKey(otp: $otp) {
      key
    }
  }
`;

export const GET_SCHOOLS = gql`
  query GetSchools {
    getSchoolQuery {
      total_pages
      page
      schools {
        updatedAt
        school_name
        phone_number
        school_id
        pg_key
        email
        merchantStatus
        disabled_modes
        platform_charges {
          platform_type
          payment_mode
          range_charge {
            upto
            charge_type
            charge
          }
        }
      }
    }
  }
`;

export const GENERATE_SCHOOL_TOKEN = gql`
  mutation GenerateSchoolToken($password: String!, $schoolId: String!) {
    generateSchoolToken(password: $password, schoolId: $schoolId) {
      token
    }
  }
`;

export const RESET_KEY = gql`
  mutation ResetKey($schoolId: String!) {
    resetKey(school_id: $schoolId) {
      pg_key
    }
  }
`;

export const GET_SETTLEMENT_REPORTS_BY_SCHOOL_ID = gql`
  query GetSettlementReportsBySchoolId {
    getSettlementReportsBySchoolId(schoolId: "659459afcc1f7c4a5eeee846") {
      settlementAmount
      adjustment
      netSettlementAmount
      fromDate
      tillDate
      status
      utrNumber
      settlementDate
      merchantId
    }
  }
`;

export const GET_SETTLEMENT_REPORTS = gql`
  query GetSettlementReports {
    getSettlementReports {
      settlementAmount
      adjustment
      netSettlementAmount
      fromDate
      tillDate
      status
      utrNumber
      settlementDate
      trustee
      schoolId
      clientId
    }
  }
`;

export const GET_TRANSACTION_REPORT = gql`
  query GetTransactionReport {
    getTransactionReport {
      collect_id
      createdAt
      order_amount
      transaction_amount
      payment_method
      school_name
      school_id
      status
      student_id
      student_name
      student_email
      student_phone
      student_receipt
      bank_reference

      details
      vendors_info {
        vendor_id
        percentage
        amount
        name
      }
      custom_order_id
    }
  }
`;

export const GET_ALL_SCHOOLS_QUERY = gql`
  query GetSchoolQuery {
    getAllSchoolQuery {
      school_name
      school_id
      pg_key
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!, $password: String!) {
    resetPassword(email: $email, password: $password) {
      msg
    }
  }
`;

export const VERIFY_TOKEN = gql`
  query VerifyToken($token: String!) {
    verifyToken(token: $token) {
      active
    }
  }
`;

export const RESET_MAILS = gql`
  mutation ResetMails($email: String!) {
    resetMails(email: $email) {
      active
    }
  }
`;

export const CREATE_INSTITUTE = gql`
  mutation CreateSchool(
    $email: String!
    $school_name: String!
    $phone_number: String!
    $admin_name: String!
  ) {
    createSchool(
      email: $email
      school_name: $school_name
      phone_number: $phone_number
      admin_name: $admin_name
    ) {
      school_name
    }
  }
`;
export const CREATE_INSTITUTE_IN_BULK = gql`
  mutation CreateBulkTrusteeSchool($schools: [SchoolInputBulk!]!) {
    createBulkTrusteeSchool(schools: $schools)
  }
`;

export const KYC_LOGON_TOKEN = gql`
  query KycLoginToken($school_id: String!) {
    kycLoginToken(school_id: $school_id) {
      token
    }
  }
`;

export const GET_PARTNER_PROFILE_DATA = gql`
  query PartnerProfileData {
    partnerProfileData {
      totalSchool
      kycDetails {
        active
        pending
        inactive
      }
    }
  }
`;

export const GET_ALL_MEMBERS = gql`
  query GetAllMembers {
    getAllMembers {
      _id
      trustee_id
      name
      email
      phone_number
      access
    }
  }
`;

export const CREATE_MEMBER = gql`
  mutation createMember(
    $name: String!
    $email: String!
    $phone_number: String!
    $access: String!
    $password: String!
  ) {
    createMember(
      name: $name
      email: $email
      phone_number: $phone_number
      access: $access
      password: $password
    )
  }
`;

export const SEND_OTP = gql`
  mutation SendOtp($type: String!) {
    sendOtp(type: $type)
  }
`;

export const VERIFY_PASSWORD_OTP = gql`
  mutation VerifyPasswordOtp($otp: String!, $password: String!) {
    verifyPasswordOtp(otp: $otp, password: $password)
  }
`;

export const UPDATE_MEMBER_DETAIL = gql`
  mutation UpdateMemberDetails(
    $name: String!
    $user_id: String!
    $email: String!
    $phone_number: String!
  ) {
    updateMemberDetails(
      name: $name
      user_id: $user_id
      email: $email
      phone_number: $phone_number
    )
  }
`;

export const UPDATE_ACCESS_LEVEL = gql`
  mutation UpdateAccessLevel($user_id: String!, $access: String!) {
    updateAccessLevel(user_id: $user_id, access: $access)
  }
`;

export const UPDATE_PROFILE_DETAIL = gql`
  mutation UpdateProfileDetails($name: String!, $brand_name: String!) {
    updateProfileDetails(name: $name, brand_name: $brand_name)
  }
`;

export const DELETE_MEMBER = gql`
  mutation DeleteMember($user_id: String!) {
    deleteMember(user_id: $user_id)
  }
`;

export const UPDATE_TRUSTEE_MAIL = gql`
  mutation UpdateTrusteeMail($email: String!, $otp: String!) {
    updateTrusteeMail(email: $email, otp: $otp)
  }
`;

export const UPDATE_TRUSTEE_PHONE_NUMBER = gql`
  mutation updateTrusteePhoneNumber($phone_number: String!, $otp: String!) {
    updateTrusteePhoneNumber(phone_number: $phone_number, otp: $otp)
  }
`;

export const RESET_MAIL = gql`
  mutation resetMails($email: String!) {
    resetMails(email: $email) {
      active
    }
  }
`;
export const CREATE_WEBHOOK = gql`
  mutation CreateWebhooks($webhookUrl: String!) {
    createWebhooks(webhookUrl: $webhookUrl)
  }
`;
export const GET_WEBHOOKS = gql`
  query GetWebhooks {
    getWebhooks {
      id
      url
    }
  }
`;

export const DELETE_WEBHOOK = gql`
  mutation DeleteWebhook($webHook_id: Float!) {
    deleteWebhook(webHook_id: $webHook_id)
  }
`;

export const TOGGLE_PAYMENT_MODE = gql`
  mutation TooglePaymentMode($mode: String!, $school_id: String!) {
    tooglePaymentMode(mode: $mode, school_id: $school_id)
  }
`;

export const GET_SINGLE_SCHOOL_MDR_INFO = gql`
  query GetSchoolMdrInfo($school_id: String!) {
    getSchoolMdrInfo(school_id: $school_id) {
      school_name
      school_id
      pg_key
      email
      merchantStatus
      disabled_modes
      platform_charges {
        platform_type
        payment_mode
        range_charge {
          upto
          charge_type
          charge
          commission
          base_charge
        }
      }
      requestUpdatedAt
    }
  }
`;

export const CREATE_MDR_REQUEST = gql`
  mutation CreateMdrRequest(
    $school_id: [String!]!
    $platform_charge: [PlatformChargesInput!]!
    $description: String!
  ) {
    createMdrRequest(
      school_id: $school_id
      platform_charge: $platform_charge
      description: $description
    )
  }
`;

export const GET_MDR_REQUESTS = gql`
  query GetTrusteeMDRRequest {
    getTrusteeMDRRequest {
      _id
      school_id
      trustee_id
      status
      description
      platform_charges {
        platform_type
        payment_mode
        range_charge {
          upto
          charge_type
          charge
          base_charge
          commission
        }
      }
      updatedAt
      createdAt
    }
  }
`;

export const CANCEL_REQUEST = gql`
  mutation CancelRequest($req_id: ID!) {
    cancelRequest(req_id: $req_id)
  }
`;

export const ADD_REMARK = gql`
  mutation AddRemarks($collect_id: String!, $remark: String!) {
    addRemarks(collect_id: $collect_id, remark: $remark)
  }
`;
export const DELETE_REMARK = gql`
  mutation DeleteRemark($collect_id: String!) {
    deleteRemark(collect_id: $collect_id)
  }
`;

export const GET_TRANSACTION_UTR = gql`
  query TransactionUtr($school_id: String!, $order_id: String!) {
    transactionUtr(school_id: $school_id, order_id: $order_id) {
      settlement_date
      utr_number
      status
    }
  }
`;

export const GET_INVOICES = gql`
  query GetInvoice($page: Int!, $limit: Int!) {
    getInvoice(page: $page, limit: $limit) {
      _id
      school_id
      invoice_status
      invoice_date
      invoice_no
      invoice_url
      createdAt
      invoice_details {
        amount_without_gst
        tax
        total
      }
    }
  }
`;

export const REQUEST_INVOICE = gql`
  mutation RequestInvoice(
    $invoice_no: String!
    $invoice_date: String!
    $hsn: String!
    $amount_in_words: String!
    $amount: Float!
    $duration: String!
    $note: String!
    $amount_without_gst: Float!
    $tax: Float!
  ) {
    requestInvoice(
      invoice_no: $invoice_no
      invoice_date: $invoice_date
      hsn: $hsn
      amount_in_words: $amount_in_words
      amount: $amount
      amount_without_gst: $amount_without_gst
      tax: $tax
      duration: $duration
      note: $note
    )
  }
`;

export const UPLOAD_INVOICE = gql`
  mutation UploadInvoice(
    $base64: String!
    $invoice_no: String!
    $duration: String!
  ) {
    uploadInvoice(base64: $base64, invoice_no: $invoice_no, duration: $duration)
  }
`;

export const FETCH_ALL_COMMISSION = gql`
  query FetchAllCommission {
    fetchAllCommission {
      school_id
      platform_type
      collect_id
      trustee_id
      commission_amount
      createdAt
      updatedAt
      payment_mode
    }
  }
`;

export const TRUSTEE_REFUND_REQUESTS = gql`
  query GetTrusteeRefundRequest {
    getTrusteeRefundRequest {
      _id
      trustee_id
      createdAt
      updatedAt
      school_id
      order_id
      status
      refund_amount
      order_amount
      transaction_amount
      school_name
      custom_id
    }
  }
`;

export const CREATE_VENDOR = gql`
  mutation CreateVendor(
    $school_id: String!
    $vendor_info: VendorInfoInput!
    $chequeBase64: String!
    $chequeExtension: String!
  ) {
    createVendor(
      school_id: $school_id
      vendor_info: $vendor_info
      chequeBase64: $chequeBase64
      chequeExtension: $chequeExtension
    )
  }
`;

export const GET_VENDORS = gql`
  query GetVendors($page: Int!, $limit: Int!) {
    getVendors(page: $page, limit: $limit) {
      totalPages
      currentPage
      vendors {
        _id
        trustee_id
        school_id
        name
        email
        phone
        createdAt
        updatedAt
        status

        bank_details {
          account_holder
          account_number
          ifsc
        }
        kyc_info {
          account_type
          business_type
          uidai
          gst
          cin
          pan
          chequeUrl
          passport_number
        }
      }
    }
  }
`;
